import * as React from 'react';
import { useEffect, useState } from 'react';
import { parseHTML } from '../../helpers/parseHTML';
import HubHero from './hub-hero';
import HubTopLink from './hub-top-link';
import dayjs from 'dayjs';

// Props
interface props {
  pageData: any
  category: string
  articles: any
}


export default function HubFeatured({pageData, category, articles}: props) {
  const {
    hubHeroArticle,
    hubCategories,
    hubTopArticles
  } = pageData;
  const [heroArticle, setHeroArticle] = useState(null);
  const [topArticles, setTopArticles] = useState(null);
  const [heroArticleType, setHeroArticleType] = useState('top');
  const [topArticleType, setTopArticleType] = useState('top');
  let featuredMounted = false;

  useEffect(() => {
    if (!featuredMounted) {
      getHeroArticle()
      getTopArticles()
      featuredMounted = true;
    }
  }, [heroArticle, heroArticleType, topArticleType, category]);

  const getHeroArticle = () => {
    if (category === 'all') {
      // Get specific hub hero article or first most recent general article
      setHeroArticle(hubHeroArticle ? hubHeroArticle : articles[0]);
      setHeroArticleType(hubHeroArticle ? 'top' : 'recent');
    } else {
      let activeCategoryData = hubCategories.filter(cat => cat.categoryTitle === category)[0];
      let filteredArticles = articles.filter(art => {
        return art.articleCategory && art.articleCategory.filter(cat => cat.categoryTitle === category).length > 0
      });
      
      // Get specific catergory hero article or first most recent based article
      setHeroArticle(activeCategoryData.categoryHeroArticle ? activeCategoryData.categoryHeroArticle : filteredArticles[0]);
      setHeroArticleType(activeCategoryData.categoryHeroArticle ? 'top' : 'recent');
    }
  }

  const getTopArticles = () => {
    // If hero is the first recent, go from the second most recent
    let articleSlice = heroArticleType == 'recent' ? [0, 4] : [0, 4];
    // Filter out hero from articles
    let articlesWithoutHero = articles.filter(art => heroArticle ? art.slug !== heroArticle.slug : true);

    if (category === 'all') {
      // Get specific top articles for the hub - or use 2nd to 5th most recent articles
      setTopArticles(hubTopArticles.length > 0 ? hubTopArticles : articlesWithoutHero.slice(...articleSlice));
      setTopArticleType(hubTopArticles.length > 0 ? 'top' : 'recent');
    } else {
      // Get specific top articles for the category - or use 2nd to 5th most recent category articles
      let activeCategoryData = hubCategories.filter(cat => cat.categoryTitle === category)[0];
      let filteredArticles = articlesWithoutHero.filter(art => {
        return art.articleCategory && art.articleCategory.filter(cat => cat.categoryTitle === category).length > 0
      });
      setTopArticles(activeCategoryData.categoryTopArticles && activeCategoryData.categoryTopArticles.length > 0 ? activeCategoryData.categoryTopArticles : filteredArticles.slice(...articleSlice));
      setTopArticleType(activeCategoryData.categoryTopArticles && activeCategoryData.categoryTopArticles.length > 0 ? 'top' : 'recent');
    }
  }

  const sortedArticles = topArticleType == 'top' ? topArticles : topArticles.slice().sort((a, b) => { 
    const bdate = b.date ? new Date(b.date) : new Date(dayjs(b.meta.publishedAt).format("YYYY-MM-DD"));
    const adate = a.date ? new Date(a.date) : new Date(dayjs(a.meta.publishedAt).format("YYYY-MM-DD"));
    // @ts-ignore
    return bdate - adate
  })

  // console.log(category, pageData, articles);
  // console.log(heroArticle, topArticles);

  return (
    <div className={`hub-featured`}>
      <div className="hub-featured__left">
        {heroArticle &&
          <HubHero article={heroArticle}/>
        }
      </div>
      <div className="hub-featured__right">
        {sortedArticles && sortedArticles.length > 0 &&
          <>
            <p className="hub-featured__link-header">{topArticleType == 'top' ? `Top Articles` : `Recent articles`}</p>
            <div className="hub-featured__link-list">
              {sortedArticles.map((article, index) => {
                return <HubTopLink article={article} key={index}/>
              })}
            </div>
          </>
        }
      </div>
    </div>
  )
}
