import * as React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

// Props
interface props {
  pageData: {
    pageTitle: string
    hubHeaderAuxText?: string
    hubCategories?: [{
      categoryTitle: string
    }]
  }
  category: string
  setFilter: Function
  articles: any
}

export default function HubHeader({pageData, category, setFilter, articles}: props) {
  const {
    pageTitle,
    hubHeaderAuxText,
    hubCategories
  } = pageData;
  
  let headerMounted = false;

  const [filteredCategories, setFilteredCategories] = useState([]);

  useEffect(() => {
    if (!headerMounted) {
      checkCategoriesHaveArticles()
      headerMounted = true;
    }
  }, []);

  const checkCategoriesHaveArticles = () => {
    let cats = [];
    for (let i = 0; i < hubCategories.length; i++) {
      const category = hubCategories[i].categoryTitle;
    
      let filteredArticles = articles.filter(art => {
        return art.articleCategory && art.articleCategory.filter(cat => cat.categoryTitle === category).length > 0
      });
      if (filteredArticles.length > 0) {
        cats.push(hubCategories[i]);
      }
    }
    setFilteredCategories(cats.reverse());
  }

  return (
    <div className={`hub-header`}>
      <div className={`hub-header__left`}>
        <h1 className={`hub-header__title`}>{pageTitle}</h1>
      </div>
      <div className={`hub-header__right`}>
        {hubHeaderAuxText && 
          <p className="hub-header__filter-aux">{hubHeaderAuxText}</p>
        }
        <button 
          className={`hub-header__filter ${category === 'all' ? 'active' : ''}`}
          onClick={() => setFilter('all')}
        >
          See All
        </button>
        {filteredCategories && filteredCategories.length > 0 && filteredCategories.map((cat, index) => {
          return (
            <button 
              className={`hub-header__filter ${category === cat.categoryTitle ? 'active' : ''}`}
              key={`filter-${index}`}
              onClick={() => setFilter(cat.categoryTitle)}
            >
              {cat.categoryTitle}
            </button>
          )
        })}
      </div>
    </div>
  )
}
