import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import Layout from "../layouts/layout"
import SEO from "../layouts/seo"
import Footer from "../global/footer"
import Nav from '../global/nav';
import HubHeader from './../hub/hub-header';
import HubFeatured from './../hub/hub-featured';
import queryString from 'query-string';
import Breadcrumbs from './../global/breadcrumbs';
import setQueryString from 'set-query-string';
import HubLatest from './../hub/hub-latest';
import pluralize from "pluralize";
import Mark from "mark.js";
interface props {
  transitionStatus: string
  data?: any
}

let markTimeout;
export default function TemplateArticleHub({ transitionStatus, data }:props) {
  const { pageData, articleNodes } = data;
  const [category, setCategory] = useState('all');
  const articles = articleNodes.edges.map(edge => edge.node);
  let hubMounted = false;
  const [userAgent, setUA] = useState<any>();
  let marked = false;
  let keyword;
  useEffect(()=>{
    
    let { query } = queryString.parse(location.search);
    if(document.location.search.indexOf("?search-term=") > -1 && !query){
      query = decodeURI(document.location.search.split("=")[1]);
      keyword = query;
    }
    
    if(keyword){
      keyword = keyword.replace(new RegExp("__pct__", "igm"), "%");
      keyword = keyword.replace(new RegExp("__and__", "igm"), "&");

      const markInstance = new Mark(document.querySelector("main"));
      clearTimeout(markTimeout);
      markInstance.unmark({
        
        done: () => {
          setTimeout(()=>{
            let modkey = pluralize.plural(keyword) === keyword ? pluralize.singular(keyword) : pluralize.plural(keyword);
            //markInstance.mark(modkey, {separateWordSearch:false}); 
            markInstance.mark(keyword, {separateWordSearch:false});
          
            let fullmatch = true;
            let mark = document.querySelector("main mark[data-markjs]"); //grab the first one
            if(mark?.childNodes.length){
              if(!marked){            
                setTimeout(()=>{                
                  mark?.scrollIntoView({block:"center", behavior:"smooth"})
                }, 1800);
                marked = true;
              }
            }else{
              fullmatch = false;
            }
            //console.log("separate words");
            
            markInstance.mark(modkey, {separateWordSearch:true}); 
            markInstance.mark(keyword, {separateWordSearch:true});

            if(!fullmatch){
              //console.log("no full match")
              mark = document.querySelector("mark[data-markjs]"); //grab the first one
              if(mark?.childNodes.length){
                if(!marked){
                  //let rect = mark?.getBoundingClientRect();
                  //console.log(mark);
                  
                  setTimeout(()=>{                
                    console.log(mark)
                    mark?.scrollIntoView({block:"center", behavior:"smooth"})
                  }, 1800);
                  marked = true;
                }
              }
            }
          }, 1000)
          
          
        }
      });
      //marked = true;
      
    }
    let links = document.querySelectorAll("a");
    let basePath = document.location.origin+document.location.pathname;
    links.forEach((a)=>{
      let link = a.getAttribute("href");
      if(link && link?.indexOf("#") > -1){
        let split = link.split("#");
        let base = split[0];
        let anchor = split[1];
        if(base === basePath){ //only apply on the same page
          a.setAttribute("href", basePath+'#'+anchor);
          a.onclick = function(anchor, event){
              event.preventDefault();
              let target = document.querySelector(anchor);
              if(target){
                target.scrollIntoView({behavior:"smooth"});
              }                            
          }.bind(this, '#'+anchor)
        }        
      }      
    });
  });
  useEffect(() => {
    if(typeof window !== 'undefined'){
      console.log(navigator.userAgent)
      setUA(navigator.userAgent)
    }
  },[]);
  
  const checkQueryString = () => {
    const parsed = queryString.parse(location.search);
    if (parsed.category) {
      let parsedFilter = parsed.category.replace(/\-+/g, ' ');
      if (pageData.hubCategories.filter(cat => cat.categoryTitle === parsedFilter).length > 0) {
        setFilter(parsedFilter, true)
      }
    }
  }

  const setFilter = (filter, alreadyInQuery) => {
    setCategory(filter);
    
    if (!alreadyInQuery) {
      setQueryString({category: filter !== 'all' ? filter.replace(/\s+/g, '-') : null})
    }
  }

  useEffect(() => {
    if (!hubMounted) {
      hubMounted = true;
      checkQueryString()
    }
  });

  return (
    <>
      <div style="display:none" for-dato-crawler>
        {pageData.metaDescription}
      </div>
      <SEO
        title={pageData.pageTitle} 
        metaDescription={pageData.metaDescription} 
        ogDescription={pageData.ogDescription} 
        ogTitle={pageData.ogTitle} 
        ogImage={pageData.ogImage} 
      />
      <Nav isWebView={userAgent ? false : true}/>
      <Layout
        className={pageData.slug}
        transitionStatus={transitionStatus}
        isWebView={userAgent ? false : true}
      >
        <Breadcrumbs pageTitle={pageData.pageTitle} parents={pageData.parentPage} transitionStatus={transitionStatus}/>
        <div className="hub">
          <div className="inner-container">
            <HubHeader 
              pageData={pageData}
              category={category}
              setFilter={setFilter}
              articles={articles}
            />
            <HubFeatured 
              pageData={pageData}
              category={category}
              articles={articles}
            />
            <HubLatest
              category={category}
              articlesPerPage={pageData.articlesPerPage}
              articles={articles}
            />
          </div>
        </div>
        <Footer isWebView={userAgent ? false : true}/>
      </Layout>
    </>
  )
}

export const query = graphql`
  query($slug: String!) {
    pageData: datoCmsArticleHub(slug: {eq: $slug}) {
      ...articleHubFragment,
      __typename
    }
    articleNodes: allDatoCmsArticlePage(filter: {parentHub: {slug: {eq: $slug}}}) {
      edges {
        node {
          ...articlePageFragment,
          __typename
        }
      }
    }
  }
`