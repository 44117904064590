import * as React from 'react';
import readingTime from 'reading-time'
import dayjs from 'dayjs';
import { treeParentRecursive, generateSlug } from '../../helpers/urlBuilder';
import AnimatedLink from '../global/animatedLink';
import stripHtml from "striptags";

// Props
interface props {
  article: any
}

export default function HubTopLink({article}: props) {
  const {
    articleCategory,
    date,
    meta,
    title,
    author
  } = article;
  let parents = [];

  treeParentRecursive(article, parents);
  let slug = generateSlug(article.slug, parents);

  const getReadTime = (article): number => {
    let readTime = 0
    article.articleComponents.forEach(art => {
      art && art.content ?
        readTime += readingTime(art.content).minutes
        : readTime;
    })
    return readTime
  }

  let readTime = getReadTime(article);

  return (
    <AnimatedLink to={slug}>
      <div className="hub-featured__tlink">
        <div className="hub-featured__tlink-top">
          {articleCategory && articleCategory.length > 0 ?
            <p>
              {articleCategory.map((cat, index) => (
                <span className="hub-featured__tlink-category" key={`cat-${index}`}>
                  {cat.categoryTitle}{index !== articleCategory.length - 1 ? ',' : ''}
                </span>
              ))}
            </p>
          : null}
          <div className="hub-featured__tlink-top-time">
            {date ?
              <time 
                dateTime={`${date}`} 
                className={`hub-featured__tlink-date`}
              >
                {dayjs(date).format("MMM D, YYYY")}
              </time>
              : meta ?
              <time 
                dateTime={`${meta.publishedAt}`} 
                className={`hub-featured__tlink-date`}
              >
                {dayjs(meta.publishedAt).format("MMM D, YYYY")}
              </time>
            : null}
            {readTime > 0 ? 
              <span className="hub-featured__tlink-time"> • {readTime.toFixed(0)} min read</span>
            : null}
          </div>
        </div>
        <p className="hub-featured__tlink-title">{stripHtml(title)}</p>
        {author &&
          <>
            <p className="hub-featured__tlink-author">{author.name}</p>
            <p className="hub-featured__tlink-role">{author.role}</p>
          </>
        }
      </div>
    </AnimatedLink>
  )
}
