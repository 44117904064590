import * as React from 'react';
import Img from 'gatsby-image/withIEPolyfill';
import readingTime from 'reading-time'
import dayjs from 'dayjs';
import { treeParentRecursive, generateSlug } from '../../helpers/urlBuilder';
import AnimatedLink from './../global/animatedLink';
import stripHtml from "striptags";

// Props
interface props {
  article: any
}

const getReadTime = (article): number => {
  let readTime = 0
  article.articleComponents.forEach(art => {
    art && art.content ?
      readTime += readingTime(art.content).minutes
      : readTime;
  })
  return readTime
}

export default function HubHero({article}: props) {
  const {
    backgroundImage,
    articleCategory,
    date,
    meta,
    title,
    articleSearchDescription,
    author
  } = article;

  let parents = [];
  treeParentRecursive(article, parents);
  let slug = generateSlug(article.slug, parents);

  let readTime = getReadTime(article);
  
  return (
    <AnimatedLink to={slug}>
      <div className="hub-featured__hero">
        {backgroundImage ? 
          <Img 
            fluid={backgroundImage.fluid}
            className={`hub-featured__hero-image`}
          />
        : null}
        <div className="hub-featured__hero-content">
          <div className="hub-featured__hero-top">
            {articleCategory && articleCategory.length > 0 ?
            <p>
              {articleCategory.map((cat, index) => (
                <span className="hub-featured__hero-category" key={`cat-${index}`}>
                  {cat.categoryTitle}{index !== articleCategory.length - 1 ? ',' : ''}
                </span>
              ))}
            </p>
            : null}
            <div className="hub-featured__hero-top-time">
              {date ?
                <time 
                  dateTime={`${date}`} 
                  className={`hub-featured__hero-date`}
                >
                  {dayjs(date).format("MMM D, YYYY")}
                </time>
                : meta ?
                <time 
                  dateTime={`${meta.publishedAt}`} 
                  className={`hub-featured__hero-date`}
                >
                  {dayjs(meta.publishedAt).format("MMM D, YYYY")}
                </time>
              : null}
              {readTime > 0 ? 
                <span className="hub-featured__hero-time">  •  {readTime.toFixed(0)} min read</span>
              : null}
            </div>
          </div>
          <p className="hub-featured__hero-title">{stripHtml(title)}</p>
          <p className="hub-featured__hero-description">{articleSearchDescription}</p>
          {author &&
            <>
              <p className="hub-featured__hero-author">{author.name}</p>
              <p className="hub-featured__hero-role">{author.role}</p>
            </>
          }
        </div>
      </div>
    </AnimatedLink>
  )
}
