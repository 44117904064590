import * as React from 'react';
import HubArticle from './hub-article';
import { MatchHeight } from 'js-match-height';
import { useEffect } from 'react';
import { isMobile } from './../../helpers/breakpoints';
import animateScrollTo from 'animated-scroll-to';
import dayjs from 'dayjs';
import { useState } from 'react';
// @ts-ignore
import ArrowLeft from './../../svg/arrow-page-blue-left.svg'
// @ts-ignore
import ArrowRight from './../../svg/arrow-page-blue-right.svg'
import ArticleList from './hub-article-list'

// Props
interface props {
  articles: any
  category: any
  articlesPerPage: number
}

export default function HubLatest({articles, category, articlesPerPage}: props) {
  const maxPageVisible = 5;
  const [articlesToShow, setArticlesToShow] = useState([0, articlesPerPage]);
  const [paginationPage, setPaginationPage] = useState(1);
  const filteredArticles = category !== 'all' ? 
    articles.filter(art => {
      return art.articleCategory && art.articleCategory.filter(
        cat => cat.categoryTitle === category
      ).length > 0
    }) : articles;
  const sortedArticles = filteredArticles.slice().sort((a, b) => { 
    const bdate = b.date ? new Date(b.date) : new Date(dayjs(b.meta.publishedAt).format("YYYY-MM-DD"));
    const adate = a.date ? new Date(a.date) : new Date(dayjs(a.meta.publishedAt).format("YYYY-MM-DD"));
    // @ts-ignore
    return bdate - adate
  })
  // Return nothing if there are no articles to show
  if (sortedArticles.length === 0) return null
    
  const totalPages = Math.ceil(filteredArticles.length / articlesPerPage);  
  const paginatedSortedArticles = sortedArticles.slice(...articlesToShow);

  useEffect(() => {
    // match heights of articles
    if (!isMobile()) {
      // const matchHeight = new MatchHeight('.article-list');
    }
  });

  const handlePageChange = (pageNumber) => {
    if (pageNumber < 1) return null;
    if (pageNumber > totalPages) return null;
    const latestArticlesEl = document.querySelector(".hub-latest");
    let firstIndex = pageNumber == 1 ? 0 : pageNumber * articlesPerPage - articlesPerPage;
    let lastIndex = pageNumber == 1 ? articlesPerPage : pageNumber * articlesPerPage;
    setPaginationPage(pageNumber);
    setArticlesToShow([firstIndex, lastIndex])
    // @ts-ignore
    setTimeout(() => animateScrollTo(latestArticlesEl.offsetTop - 150, {speed: 2000}), 100);
  }

  const calcPageButtons = () => {
    let pageButtonElements = [];
    let buffer = (maxPageVisible - 1) / 2; 

    for (let i = 0; i < totalPages; i++) {
      let thisPage = i + 1;
      // Show all pages if in the visible limit
      if (totalPages <= maxPageVisible) {
        
        pageButtonElements.push(
          <button 
            className={`hub-latest__pagination-item number ${paginationPage == thisPage ? 'active' : ''}`} 
            key={thisPage}
            onClick={() => handlePageChange(thisPage)}
          >
            {thisPage}
          </button>
        )
      // Show all pages if in the visible limit
      } else if (
        // If pagination page
        thisPage == paginationPage ||
        // Pages either side of buffer number
        (thisPage >= (paginationPage - buffer) && thisPage < paginationPage) ||
        (thisPage <= (paginationPage + buffer) && thisPage > paginationPage) ||
        // On either end under max pages visible
        thisPage <= maxPageVisible && paginationPage <= buffer + 1 ||
        thisPage > (totalPages - maxPageVisible) && paginationPage >= (totalPages - buffer)
      ) {
        
        pageButtonElements.push(
          <button 
            className={`hub-latest__pagination-item number ${paginationPage == thisPage ? 'active' : ''}`} 
            key={thisPage}
            onClick={() => handlePageChange(thisPage)}
          >
            {thisPage}
          </button>
        )
      }
    }
    return pageButtonElements
  }

  return (
    <div className="hub-latest">
      <p className="hub-latest__title"><strong>Latest</strong> articles</p>
      <ArticleList sortedArticles={paginatedSortedArticles} />
      {sortedArticles.length > articlesPerPage ? 
        <div className="hub-latest__pagination-wrapper">
          <div className="hub-latest__pagination">
            <button 
              className={`hub-latest__pagination-item hub-latest__pagination-first disabled`}
              onClick={() => handlePageChange(1)}
            >
              First
            </button>
            <button 
              className={`hub-latest__pagination-item hub-latest__pagination-prev disabled`}
              onClick={() => handlePageChange(paginationPage - 1)}
            >
              <ArrowLeft/>
            </button>
            {calcPageButtons()}
            <button 
              className={`hub-latest__pagination-item hub-latest__pagination-next disabled`}
              onClick={() => handlePageChange(paginationPage + 1)}
            >
              <ArrowRight/>
            </button>
            <button 
              onClick={() => handlePageChange(totalPages)}
              className={`hub-latest__pagination-item hub-latest__pagination-last disabled`}
            >
              Last
            </button>
          </div>
          <p className="hub-latest__pagination-pages">Page {paginationPage} of {Math.ceil(sortedArticles.length / articlesPerPage)}</p>
        </div>
      : null}
    </div>
  )
}
